<template>
  <v-row no-gutters>
    <v-col>
      <div id="event-list">
        <!-- app drawer -->

        <event-add-update
          v-model="isAddUpdateEventSidebarActive"
          :event-type="eventType"
          :event-item="eventItem"
          @refetch-data="fetchEvents"
          @upload-image="uploadImage"
          @save-event-event="saveEventEvent"
        ></event-add-update>

        <event-filter v-model="isFilterEventSidebarActive" @filter-data="filterData"> </event-filter>

        <Image-upload
          v-model="isUploadEventImageActive"
          :data="dataUpload"
          :isUploadImageModul="modul"
          :isActive="image_isActive"
          :isImageSize="imageSize"
          @refetch-data="fetchEvents"
          @save-image-event="saveImageEvent"
        />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiBullhorn }}
              </v-icon>
              Duyurular
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn v-if="userData.role !== 'PLSYR'"
            
              :class="{
                'bg-gradient-nightDark': !isDark,
                'bg-gradient-primary': isDark, // isDark ise farklı bir stil uygula
                'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
              }"
              depressed
              x-large
              @click="addEvent()"
              @mouseenter="activeHover = true" @mouseleave="activeHover = false"
            >
              <span>Duyuru Ekle</span>
              <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="filteredTableColumns"
            :items="eventListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalEventListTable"
            :loading="loading"
          >
            <!-- Text -->
            <!-- Resimleri göstermek için slot -->
                        
     <!--        <template #[`item.dosya_adi`]="{ item }">
                          
              <v-card
                tile
                class="rounded d-flex align-center justify-center"
                width="40"
                height="40"
                :color="item.images ? 'white' : 'primary'"
              >                
              <v-img                
                  v-for="(image, index) in item.images"                     
                  :key="index"
                  :src="'https://portalapi.tukas.com.tr/' +image"
                  width="30" 
                  height="30" 
                  class="mr-2"
              ></v-img>
                <v-icon  v-if="item.images.length <= 0">
                  {{ icons.mdiCameraOff }}
                </v-icon>
              </v-card>
            </template> -->
            <template #[`item.dosya_adi`]="{ item }">
              <v-tooltip v-if="item.images" right color="white">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    tile
                    class="rounded d-flex align-center justify-center"
                    :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :color="item.images ? 'white' : 'primary'"
                    v-on="on"
                  >
                  <v-img                
                  v-for="(image, index) in item.images"                     
                  :key="index"
                  :src="'https://portalapi.tukas.com.tr/' +image"
                  :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  class="mr-2"
              ></v-img>
                <v-icon  v-if="item.images.length <= 0">
                  {{ icons.mdiCameraOff }}
                </v-icon>                
                  </v-card>
                </template>
                <v-img width="250" height="250" :src="'https://portalapi.tukas.com.tr/' + item.images"></v-img>
              </v-tooltip>
              <v-card
                v-else
                tile
                class="rounded d-flex align-center justify-center"
                :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :color="item.images ? 'white' : 'primary'"
              >

              <v-img                
              v-for="(image, index) in item.images"                     
              :key="index"
              :src="'https://portalapi.tukas.com.tr/' +image"
              :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
              :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
              class="mr-2"
          ></v-img>
            <v-icon  v-if="item.images.length <= 0">
              {{ icons.mdiCameraOff }}
            </v-icon>
            
              </v-card>
            </template>

            <template #[`item.is_active`]="{ item }">
              <v-chip :color="item.is_active === '1' ? 'success' : 'warning'" link outlined small>
                {{ item.is_active === '1' ? 'Aktif' : 'Pasif' }}
              </v-chip>
            </template>
            
            <template #[`item.content`]="{ item }">
              <div v-if="userData.role !== 'PLSYR'" v-text="item.content"></div>
              
            </template>

            <template #[`item.actions`]="{ item }">
              <template  v-if="userData.role !== 'PLSYR'">              
              <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="editEvent(item)">
                Düzenle
              </v-btn>

              <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed color="error" x-small plain v-bind="attrs" @click="eventDelete(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Duyuru Sil</span>
              </v-tooltip>

              <v-tooltip v-if="item.images.length>0" bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="warning"
                    x-small
                    plain
                    class="rounded-l-0 rounded-r-xl"
                    v-bind="attrs"
                    @click="eventImageDelete(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resimi Kaldır</span>
              </v-tooltip>
              <v-tooltip v-else bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="nightDark"
                    x-small
                    class="rounded-l-0 rounded-r-xl"
                    v-bind="attrs"
                    @click="eventImageUpload(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiCamera }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resim Yükle</span>
              </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="warning" x-small plain v-bind="attrs" @click="openDialog(item)" v-on="on">
                      <v-icon small>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Detayını Oku</span>
                </v-tooltip>
              </template>
            </template>
           
          </v-data-table>
        </v-card>

        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              {{ selectedEvent.baslik }}
              <v-spacer></v-spacer>
              <v-icon @click="dialog = false" style="cursor: pointer; color: #ff4081;" >{{ icons.mdiClose }}</v-icon>
            </v-card-title>
            <v-card-text>
              <p>{{ selectedEvent.content }}</p>
        
              <template v-if="selectedEvent.image">
                <v-img  :src="'https://portalapi.tukas.com.tr/' + selectedEvent.image"
                width="100%"
                max-height="300" 
                class="mt-2"
                style="object-fit: cover;"></v-img>
              </template>
              <template v-else>
                <v-icon large class="ma-0" style="font-size: 100px; color: #cccccc;">
                  {{ icons.mdiCameraOff }}
                </v-icon>
              </template>
          
           

            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageUpload from '@/components/ImageUpload.vue'
import store from '@/store'
import {
  mdiBullhorn,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import eventStoreModule from '../eventStoreModule'
import eventAddUpdate from './EventAddUpdate.vue'
import eventFilter from './EventFilter.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
export default {
  components: {
    eventAddUpdate,
    eventFilter,
    ImageUpload,
    VSnackbars,
    ImageUpload,
  },
  setup() {
    const { isDark } = useAppConfig()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const EVENT_APP_STORE_MODULE_NAME = 'app-event'
    const snackbars = ref([])

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
      }
    })

    const isAddUpdateEventSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterEventSidebarActive = ref(false)
    const isUploadEventImageActive = ref(false)
    const dataUpload = ref({})
    const eventListTable = ref([])
    const eventType = ref('add')
    const eventItem = ref({})
    //const filteredTableColumns = ref([])
    const tableColumns = 
    [
    {
        text: 'Resim',
        value: 'dosya_adi',
        width: '100px',
      },
      
      {
        text: 'Durum',
        value: 'is_active',
        width: '90px',
      },

      {
        text: 'Başlık',
        value: 'baslik',
        width: '200px',
      },

      {
        text: 'İçerik',
        value: 'content',
      },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalEventListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const eventTotalLocal = ref([])
    const selectedRows = ref([])

    const eventImageUpload = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const uploadImage = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const eventImageDeleteOLD = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.pictures.bresim[0].uploadid,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Duyurusun Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const eventImageDelete = item => {
      
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          debugger
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.imagesUploadid,
            })
            .then(() => {
              debugger
              snackbars.value.push({
                message: `${item.baslik} Ürünün Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const eventDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Duyurusu silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deleteEvent',
              id: item.id,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Duyurusu Silindi`,
                color: 'error',
                timeout: 5000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const saveEventEvent = val => {
      snackbars.value.push(val)
    }
    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const editEvent = item => {
      eventItem.value = item
      eventType.value = 'edit'
      isAddUpdateEventSidebarActive.value = true
    }
    const addEvent = () => {
      eventItem.value = {}
      eventType.value = 'add'
      isAddUpdateEventSidebarActive.value = true
    }
    const filterData = item => {
      idFilter.value = item.id || null
      statusFilter.value = item.aktif || null
      urunKodFilter.value = item.urun_kodu || null
      baslikFilter.value = item.baslik || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    const fetchEvents = () => {
      const query = {
        options: options.value,
        id: idFilter.value,
        aktif: statusFilter.value,
        urun_kodu: urunKodFilter.value,
        baslik: baslikFilter.value,
        customer: customerFilter.value,
      }
      const method = 'getEvents'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          if (response.error === 0) {
          //eventListTable.value = response.response.result
          eventListTable.value = response.response.result.map(msg => {
                  msg.images = []; // 
                  msg.imagesUploadid = []; //                   
                  return msg;
                });               

              totalEventListTable.value = Number(response.response.pagination.totalRec)   
              
                // Her bir mesajın resimlerini yüklemek için döngü
                response.response.result.forEach(msg => {
                    fetchMediaItems(msg.id); // Mesaj ID'sini kullanarak resimleri yükle
                });         
           }
          
            loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchEvents()
    })

    const fetchMediaItems = (messageId) => {
   
   const method = 'getMediaItems';
   const localid = messageId;
   const modul = 'events';
   
    store.dispatch('postMethod', { localid , modul , method })
       .then(response => {
           if (response.error === 0) {
               const kresimFiles = response.files.bresim;
               const imageUrls = kresimFiles.map(file => file.dosya_adi);
               const uploadid = kresimFiles.map(file => file.uploadid);
               
               // Resimleri ilgili mesaja ekleme
               const message = eventListTable.value.find(msg => msg.id === messageId);
               if (message) {
                   message.images = imageUrls; // Resim URL'lerini ekle
                   message.imagesUploadid = uploadid; // Resim URL'lerini ekle        
               }
           } 
       })
       .catch(error => {
           console.error('Fetch hatası:', error);
       });
     };

    return {
      eventImageDelete,
      statusFind,
      eventItem,
      editEvent,
      addEvent,
      eventType,
      isFilterNumber,
      filterData,
      eventListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalEventListTable,
      loading,
      options,
      eventTotalLocal,
      isAddUpdateEventSidebarActive,
      isFilterEventSidebarActive,
      isUploadEventImageActive,
      selectedRows,
      fetchEvents,
      eventDelete,
      snackbars,
      eventImageUpload,
      dataUpload,
      uploadImage,
      saveEventEvent,
      saveImageEvent,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,
      fetchMediaItems,
      userData,
      //filteredTableColumns,
      isDark,
      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiBullhorn,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      activeHover : false,
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      modul:"events" , 
        image_isActive:1,
        imageSize :[
          {
            bresim:900 ,
            dresim:600,
            oresim:450,
            kresim:50 
          }
        ],
        dialog: false,
        selectedEvent: {},  
    }
  },

  watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
    openDialog(item) {
      this.selectedEvent = {
        baslik: item.baslik,
        image: item.bresim , // Boşsa varsayılan resim || 'https://picsum.photos/300'
        content: item.content, // Varsayılan açıklama
      };
      this.dialog = true; // Dialog'u aç
    },
  },
  computed: {
    filteredTableColumns() {
      // Eğer kullanıcı PLSYR ise içerik kolonunu gizle
      return this.userData.role === 'PLSYR'
        ? this.tableColumns.filter(column => column.value !== 'content')
        : this.tableColumns;
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
